import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Humanity/Socialscience/1.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/Socialscience/2.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/Socialscience/3.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/Socialscience/4.jpg';
// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';


const SocialscienceCCA2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/social-cca/1.jpg`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/social-cca/2.jpg`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/social-cca/3.jpg`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/social-cca/4.jpg`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h5' align='center' >
      SOCIAL SCIENCE CCA 
                                </Typography>
                                <br></br>
                                <Typography variant='h7' align='justify' >
                                Class 4: Diversity Montage
                                Class 5: Continental Montage 

                                </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 4 & 5  &  Date: 24 & 25 JANUARY 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The final rounds of Social Science CCA, held in the fourth week of January saw active and enthusiastic participation by the students of Classes 4 and 5. The event gave an insight to the students that the world celebrates Unity in Diversity which is a living proof that despite different religions and cultures, people of the world live together in peace and harmony.  
        <br></br>
        Students of Class 4 displayed their collage work and spoke effectively on the topic  ‘Diversity Montage’, enhancing the beauty and diversity of our country in a unique way.
        Students of Class 5 displayed their collage work and spoke enthusiastically on the topic ‘Continental Montage’ using relevant props and charts, adding an artistic touch to the topic. It involved creating visual compositions that represent the unique features, cultures and landscapes of different continents.

        <br></br>
        This activity exemplified the limitless diversities of India and the world, which is a home to a cornucopia of ethnicities, languages, religions and cultures, each with their own set of customs and beliefs. The goal is likely to combine the artistic expression with informative content making the learning experience enriching and memorable. The lessons learnt through this journey will stay with the students throughout their lives and make them appreciate the aspect of diversity.
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “The Beauty of the World lies in the Diversity of its people”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default SocialscienceCCA2023;